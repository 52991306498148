//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import PageHeader from '@/components/tools/PageHeaderCustom.vue';
import UploadPhoto from '@/components/spec/UploadPhoto.vue';
import * as pubsub from '@/pubsub';
import { urlByAnyData } from '@/utils/util';
import ChangeStatusModal from './modal/ChangeStatusModal';

export default {
  components: {
    PageHeader,
    UploadPhoto,
    ChangeStatusModal,
    IframeReview: () => import('./iframe-code-list/Index.vue'),
    EditPortalWebsiteRenderer: () => import('./portal-site-editor/Index.vue')
  },

  data() {
    return {
      form: this.$form.createForm(this),
      page: {},
      loading: false,
      submitLoading: false,
      publicLoading: false,
      uploadLoading: false,
      webhookTokens: [],
      webhookToken: '',
      webhookStatus: false,
      previewModalVisible: false,
      thumbnail: {
        url: '',
        alt: ''
      },
      isIncludeCodes: false,
      iframeVisible: false,
      changeStatus: {
        visible: false,
        havePublishVersion: true,
        loading: false
      },
      dictionary: []
    };
  },
  computed: {
    ...mapGetters('user', ['userInfo']),
    ...mapGetters('project', ['projectById']),
    ...mapGetters('dictionary', ['dictionaryTree']),
    companyId() {
      return this.userInfo.company_id;
    },
    pageId() {
      return this.$route.params.pageId;
    },
    projectId() {
      return this.$route.params.projectId;
    },
    formItemLayout() {
      return {
        labelCol: { xs: { span: 8 }, sm: { span: 3 }, lg: { span: 3 } },
        wrapperCol: { xs: { span: 16 }, sm: { span: 18 }, lg: { span: 18 } }
      };
    },
    formTailLayout() {
      return {
        labelCol: { xs: { span: 8 }, sm: { span: 3 }, lg: { span: 3 } },
        wrapperCol: {
          xs: { span: 16, offset: 8 },
          sm: { span: 20, offset: 3 },
          lg: { span: 10, offset: 3 }
        }
      };
    },
    pathToCodes() {
      return `/projects/${this.projectId}/pages/${this.pageId}/codes`;
    },
    pathToPage() {
      return `${window.location.origin}/projects/${this.projectId}/pages/${this.pageId}`;
    },
    pathToCategorySetting() {
      return `/projects/${this.projectId}/pages/${this.pageId}/category`;
    },
    formItemOptions() {
      return {
        name: {
          rules: [
            { required: true, message: this.$t('Please input your page name') },
            { max: 50, message: this.$t('Page name max length 50') }
          ],
          initialValue: this.page.name
        },
        description: { initialValue: this.page.description }
      };
    },
    folderNameS3() {
      return `${process.env.VUE_APP_S3_PROJECT_THUMBNAIL}/${this.pageId}/${uuidv4()}_${
        this.page.name
      }`;
    },
    isCatalogPage() {
      return this.page.template === 'CATALOG';
    },
    currentProject() {
      return this.projectById(this.projectId) || {};
    },
    projectDictionary() {
      return this.currentProject?.dictionary || []
    },
    isChangeProjectStatusWarning() {
      return !this.projectById(this.projectId)?.is_public && !this.page.ispublic;
    },
    dictionaryTreeData() {
      if (this.dictionary.length < 5) {
        return this.dictionaryTree.map(lev1 => ({ ...lev1, title: this.$t(lev1.title) }))
      }
      const newDictionaryTree = this.dictionaryTree.map(lev1 => {
        const children = lev1.children.map(dic => ({
          ...dic,
          disabled: !this.dictionary.includes(dic.value)
        }))
        return {
          ...lev1,
          title: this.$t(lev1.title),
          children
        }
      })
      return newDictionaryTree
    },
    isUsedProjectDictionaryAsDefault() {
      const pageDictionary = JSON.stringify((this.dictionary || [])?.sort())
      const projectDictionary = JSON.stringify((this.projectDictionary || [])?.sort())
      return this.projectDictionary?.length > 0 && pageDictionary == projectDictionary
    }
  },
  async created() {
    try {
      this.loading = true;
      const data = await this.$s.api.page.getPageById(this.projectId, this.pageId);
      const webhookTokensData = await this.$s.api.page.getWebhookTokens(this.pageId);
      this.webhookTokens = webhookTokensData;
      this.webhookToken = webhookTokensData.length
        ? webhookTokensData[webhookTokensData.length - 1].token
        : '';
      this.page = data;
      this.webhookStatus = data.webhookStatus === 'ACTIVE' ? true : false;
      this.thumbnail = {
        url: data.thumbnail?.url,
        alt: data.thumbnail?.alt
      };
      this.dictionary = (data?.dictionary?.length > 0
        ? data.dictionary
        : this.currentProject?.dictionary
      ) || []
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions('page', ['updatePageById']),
    ...mapActions('project', ['updateProjectById']),
    handleSubmitForm() {
      this.form.validateFields((err, values) => {
        if (err) return;
        const { name, description } = values;
        const input = {
          status: this.page.status,
          name,
          description,
          thumbnail: this.thumbnail,
          webhookStatus: this.webhookStatus ? 'ACTIVE' : 'INACTIVE',
          dictionary: this.dictionary?.sort() || []
        };
        this.updatePageBasicInfo(input);
      });
    },
    async updatePageBasicInfo(payload) {
      try {
        this.submitLoading = true;
        await this.$s.api.page.editPageById(this.projectId, this.pageId, payload);
        if (
          this.webhookStatus &&
          !this.webhookTokens.filter(i => i.token === this.webhookToken).length
        ) {
          let webhookInput = {
            token: this.webhookToken
          };
          await this.$s.api.page.upsertWebhookToken(this.pageId, webhookInput);
        }
        this.$notification.success({ message: this.$t('This page has been updated') });
        this.$router.push(`/projects/${this.projectId}/pages`);
      } catch (error) {
        this.$notification.error({ message: this.$t('This page update failed') });
      } finally {
        this.submitLoading = false;
      }
    },
    changeStatusWebhook(e) {
      if (e && !this.webhookToken) this.generateToken();
    },
    async handleOpenStatusModal() {
      if (!this.page.ispublic) {
        const totalPublished = await this.$s.api.page.countSiteOpts(this.page.id, {
          where: { status: 'PUBLISHED' }
        });
        this.changeStatus.havePublishVersion = totalPublished.count > 0;
      } else {
        this.changeStatus.havePublishVersion = true;
      }
      this.changeStatus.visible = true;
    },
    generateToken() {
      let token = 'xxxxxxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
      this.webhookToken = token;
    },

    copyClipBoard(value) {
      this.$copyText(value)
        .then(() => {
          this.$message.success(this.$t('Copy completed'));
        })
        .catch(err => {
          console.log(err);
          this.$message.error(this.$t('Copy failed'));
        });
    },
    async handlePageExport() {
      try {
        await this.$s.api.page.exportPageById(this.pageId, { includes: ['codes'] });
        const subscribePayload = {
          collectionName: 'EXPORT_PAGE',
          method: 'GET',
          modelId: this.pageId
        };
        this.$notification.open({
          message: this.$t('Exporting... Please wait for a little bit!'),
          duration: 0,
          icon: <a-icon type="smile" style="color: #108ee9" />
        });
        pubsub.subscribe(this.sockets, subscribePayload, response => {
          this.$notification.destroy();
          if (response.status === 'FAILED') {
            this.$notification.error({
              message: this.$t('Exporting the page failed.'),
              description: response?.message || ''
            });
          } else {
            const url = urlByAnyData({ data: response?.data, contentType: 'application/json' });
            const a = document.createElement('a');
            a.href = url;
            a.download = this.page?.name || 'file-name';
            a.click();
            a.remove();
            this.$notification.success({
              message: this.$t('Exporting the page succeed.')
            });
          }
          pubsub.unSubscribeAll(this.sockets);
        });
      } catch (error) {
        console.log(error);
        this.$notification.error({
          message: this.$t(error?.message || 'error')
        });
      }
    },
    handleBackToPageList() {
      this.$router.go(-1);
    },
    async handleChangeStatus() {
      this.changeStatus.loading = true;
      let includes = [];
      const isChangePublic = !this.page.ispublic;
      if (this.isCatalogPage) {
        includes = this.isIncludeCodes ? ['code'] : [];
      }
      if (this.isChangeProjectStatusWarning) {
        includes = includes.concat(['project']);
      }
      const successNotifyMessage = {
        true: this.$t('This page is public.'),
        false: this.$t('This page is private.')
      };
      const errorNotifyMessage = {
        true: this.$t('This page public failed.'),
        false: this.$t('This page private failed.')
      };
      try {
        this.publicLoading = true;
        await this.$s.api.page.editPageById(this.projectId, this.pageId, {
          ispublic: isChangePublic,
          includes
        });
        this.updatePageById({
          id: this.pageId,
          ispublic: isChangePublic
        });
        if (includes.includes('project')) {
          this.updateProjectById({ id: this.projectId, is_public: isChangePublic });
        }
        this.page.ispublic = isChangePublic;
        this.$notification.success({ message: successNotifyMessage[isChangePublic] });
        this.changeStatus.havePublishVersion = true;
      } catch (error) {
        console.log(error);
        this.$notification.error({ message: errorNotifyMessage[isChangePublic] });
      } finally {
        this.publicLoading = false;
        this.changeStatus.loading = false;
        this.changeStatus.visible = false;
      }
    },
    async handleAlertPublishNewVersion(page) {
      if (this.changeStatus.visible) {
        this.iframeVisible = false;
        this.changeStatus.loading = true;
        await new Promise(resolve => setTimeout(resolve, 1000));
        this.changeStatus.havePublishVersion = true;
        this.changeStatus.loading = false;
      } else if (!page.ispublic) {
        this.handleOpenStatusModal();
      }
    },
    handleOpenDictionary(dicName) {
      this.$router.push(`/settings/speech-setting?dicName=${dicName}`);
    },
  },
  mounted() {
    // setTimeout to wait treeselect component is rendered on DOM tree (nextTick is not working in this case)
    setTimeout(() => {
      // Handle event TreeSelect listener
      const treeSelectElement = this.$refs?.treeSelectRef?.$el;
      console.log(this.$refs)
      treeSelectElement?.addEventListener('click', (event) => {
        if (event?.target?.className === 'ant-select-selection__choice__content') {
          const dicName = event?.target?.textContent
          console.log(dicName)
          this.handleOpenDictionary(dicName)
        }
      });
    }, 500);
  },
  beforeDestroy() {
    // Handle remove event TreeSelect listener
    const treeSelectElement = this.$refs?.treeSelectRef?.$el;
    treeSelectElement?.removeEventListener('click', null);
  },
};
